.backdrop{
    transition: 0.5s;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    visibility: hidden;
    &.fadeIn {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.6);
        transition:all 0.5s;
        visibility: visible;
        z-index: 105;
      }
      &.fadeOut {
        opacity: -1;
        visibility: hidden;
        transition: 0.5s;
        transition:all 0.5s;
      }
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 1050;
    // width: 100vw;
    // height: 100vh;
    // background-color: #000;

    // &.fade {
    //     transition: opacity .15s linear;
    // }
    // &.show {
    //     opacity: .5;
    // }
}