.overlay {
  border-radius: 34px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 5px;
 .content {
     border-radius: 34px;
    }
  

  }
  .modal_dialog {
    width: 80%;
   }
  .fullscreen-popup {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // // transform: translate(-50%, -50%);
    // background-color: #ffffffb8;
    // padding: 20px;
    // width: 100%;
    // overflow-y: auto;
    // border-radius: 4px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    // height: 100%;

  }
  
  .popup-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .popup-title {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .language-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20%;

  }
  
  .box-container {
  
    img{
      width: 100%;
    }
  }
  
  .box {
    min-width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px #FF5252;
    padding: 5px;
    border-radius: 10px;
  }

  .button{
    background-color: #FF5252;
    border-color: #FF5252;
    width: 80%;
    border-radius: 25px;

    &:hover{
      background-color: #FF5252;
      border-color: #FF5252;
    }
  }
  
  .box img {
    width: 70%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .box p {
    font-size: 16px;
  }

  .powered{
    margin-top:10% ;
  }

  .bottom-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  
  .bottom-link-text {
    text-decoration: underline;
    color: #333;
  }
  .popup-title-2{
    font-size: 18px;
    font-weight: 600;
  }