.modalBody{
//    padding: 10px;
   position: relative;
   background-color: #fff;
   width: 100vw;
   height: 100vh;
   height: 90vh;
   border-radius: 20px 20px 0 0;
   display: flex;
   flex-direction: column;

//    &.hi{
//     -webkit-animation: bottom-to-top-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//     animation: bottom-to-top-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }
.head{
    display: flex;
    flex-direction: column;
}
}
.line{
    margin: 10px  auto 0;
    width: 100px;
    height: 5px;
    border-radius: 10px;
    background-color: #cccc;

}
.headline{
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
}
.weekBox{
    display: flex;
    margin: 0 -5px;
    margin-top: 20px;
    justify-content: space-between;
    padding: 0 10px;
}
.weekItem{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.disable{
        opacity: 0.4;
        pointer-events: none;
        pointer-events: none;
    }
    .week{
        font-weight: 700;
        font-size: 11px;
        color: #777;
    }
    .numberDate{
       font-weight: 500;
       width: 30px;
       height: 30px;
       display: flex;
       justify-content: center;
       align-items: center;
       background-color: #eee;
       border-radius: 5px;
       color: #777;

    }   
    .border{
       margin-top: 3px;
       width: 100%;
       height: 3px;
    //    background-color: rgb(141, 217, 65);
           background-color: #ff5252;
    
       border-radius: 5px;

    }
    &.active{
       .numberDate{
        //    background-color: rgb(141, 217, 65);
                   background-color: #ff5252;
        
           color: #fff;
       }
    }
}
.timeContainer{
    display: flex;
    // height: calc(100% - 256px);
    overflow: hidden;
    // margin-top: 10px;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #eee;


.timeBox{
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: auto;
    height: 100%;
    .timeRow{
        display: flex;
        // margin-bottom: 10px;
        flex-direction: column;
        justify-content: center;
        .time{
        margin-right: 10px;
        display: flex;
        align-items: center;
        >span{
            margin-right: 10px;
            color: #858585;

        }
        .border{
            width: 100%;
            height: 1px;
            background-color: #ececec;
        }
        // align-items: center;
            
        }
        .contentBox{
        width: 100%;
        display: flex;
        align-items: center;
         .checkedBox{
            width: 30px;
            height: 30px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            .check{
                width: 25px;
                height: 25px;
                border-radius: 100%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    fill: #ececec;

                }
                // &.active{
                //     svg{
                        fill: rgb(141, 217, 65);
                                                // fill: #ff5252;
                        

                //     }
                // }
            }
         }
     
        .contentItem{
        display: flex;
        align-items: center;
        padding: 5px 10px;

        margin: 0;
        // width: 100%;
        flex: 1;
        background-color: #f7f7f7;
        border-radius: 4px;
      
            .icon{
                margin-right: 10px;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: white;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .text{
                // color:#fff;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }

    &.active{
        .contentBox{
            .check{
              svg{
                //   fill: rgb(141, 217, 65);
                                  fill: #ff5252;
                
              } 
            }
        }
        
        .contentItem{
            // background-color: rgb(141, 217, 65);
                        background-color: #ff5252;
                        color:#fff;
            
        }
}
}
    
}
}
.footer{
    height:50px;
    border-top: 1px solid #eee;
    // position: relative;
    // .staticFooter{
    //     background-color: #fff;
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}

.acceptButton{
    font-size: 12px;
    background-color: #ff5252;
}
.selectedDate{
    // margin-top: 20px;
    font-size: 12px;
    font-weight: 800;
 }

 .clockBox{
    display: flex;
    // justify-content: center;
    flex-direction: column;
 }
.dateInput{
    border: 1px solid #eee;
    padding: 10px;
}
.image{
    border-radius: 0 0 10px 10px;
}
.content{
    padding: 1rem;
}

.close{
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 10;
    color: rgb(255, 255, 255);
    width: 25px;
    height: 25px;
    background-color:#0000006d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 500;
}

.parentElement{
    position: relative;

.element{
&.button{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
    transition: visibility 0s linear 0.33s, opacity 0.33s linear ,transform  0.33s linear;
    &.show{
        visibility: visible;
        opacity: 1;
        
        transition-delay: 0s;
        margin-bottom: 0;
        transform: translateY(0);

    }
    &.hide{
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
    }
}
}
.changeCartButtonsParent{
    button{
        top: unset !important;
        right: unset !important;
        left: unset !important;
        bottom: unset !important;
    }
}



@keyframes bottom-to-top-animation {
    0% {
      opacity: 1;
      display: none;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      display: none;
      -webkit-transform: translateY(-80px);
      transform: translateY(-80px);
    }
  }
  
  @-webkit-keyframes bottom-to-top-animation {
    0% {
      opacity: 1;
      display: none;
      transform: translateY(0);
    }
  
    100% {
      opacity: 0;
      display: block;
      transform: translateY(-80px);
    }
  }
  