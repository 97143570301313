.cartItems {
	// height: calc(100vh - 405px);
	overflow: auto;
	padding: 0 10px;
	flex-direction: column-reverse;
	display: flex;
	padding-top: 50px;
	// padding-bottom: 370px;
	padding-bottom: 405px;
	&.full {
		// padding-bottom: 200px;
		padding-bottom: 300px;

		// height: calc(100vh - 300px);
		&.withTour {
			padding-bottom: 295px;
			// height: calc(100vh - 295px);
		}
	}
	&.withTour {
		padding-bottom: 465px;

		// height: calc(100vh - 465px);
	}
}
.navbar {
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	border-bottom: 1px solid #eee;
}
.discount {
	color: #50c878 !important;
	&.active {
		background-color: #50c878 !important;
		color: #fff !important;
		padding: 15px 10px !important;
	}
	font-weight: 500;
	.icon {
		margin-right: 5px;
	}
	.label {
	}
}
.calculatorBox {
	border-radius: 15px 15px 0 0 !important;
}

.beforePrice {
	text-decoration: line-through;
	color: #8c8c8c;
	font-size: 14px;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}
.networkDryTitle {
	font-size: 13px;
}
.fixedBottom {
	position: fixed;
	width: 100%;
	z-index: 100;
	bottom: 0;
	left: 0;
	background-color: #fff;
}
.specialOffer {
	// margin-bottom: 20px;
}
