// .CardImage {
//     border-radius: 10px 0 0 10px;
//     // height: 100% !important;
//     // max-width: 123px;
//     height: 123px;
//     width: 123px;
//     object-fit: cover;

// }

.productCard {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.03);
    // height: 123px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .cardText {
        // max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
}
.radius{
    border-radius: 10px !important;

}
.orgPrice {
    font-size: 16px;
    text-decoration: line-through;
    color: #989898;
}

.price {
    position: relative;
    margin-left: 5px;
}

.cardText {
    // font-size: 1rem !important;
}

// .addBasketButton {
//     position: absolute ;
//     bottom: 10px;
//     // left: 10px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//     background-color: #50C878;
//     color: white;
//     font-size: 1rem;
//     position: relative;
//     cursor: pointer;
//     border: none;
//     &::before {
//         content: "+";
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         font-size: 2.5rem;

//     }
// }

