.skeleton {
	--bg: #e1e1e1;
	--glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
	position: relative;
	background-color: var(--bg);
	overflow: hidden;
}

.skeleton::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--glare-bg);
	transform: translateX(-100%);
	animation: skeleton-glare 1.75s infinite ease-out;
	z-index: 1;
}

@keyframes skeleton-glare {
	to {
		transform: translateX(100%)
	}
}