.container {
	padding: 15px;
	background-color: #fff;
}

.headline {
	font-size: 1.5rem;
}
.banner {
	display: flex;
	img {
		width: 100%;
		border-radius: 8px;
	}
}

.paragraph {
	margin-top: 2rem;
	line-height: 2;
	font-weight: 500;
	color: #777;
}

.list {
	li {
		list-style: outside;
	}
}

.buttonWrap {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	a {
		background-color: #033466;
		color: white;
		padding: 5px 10px;
		width: 90%;
		border-radius: 8px;
		font-size: 18px;
		border: none;
		text-align: center;
	}
}
