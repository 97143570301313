.fullscreen-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 99;
}

.fullscreen-popup {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffffb8;
	padding: 20px;
	width: 100%;
	overflow-y: auto;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	height: 100%;
}

.popup-content {
	text-align: center;
	margin-bottom: 20px;
}

.popup-title {
	font-size: 24px;
	margin-bottom: 20px;
}

.language-title {
	font-size: 16px;
	margin-bottom: 20px;
	margin-top: 20%;
}

.box-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

.box {
	min-width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: solid 1px #ff5252;
	padding: 5px;
	border-radius: 10px;
}

// .box img {
//   width: 60%;
//   max-height: 200px;
//   object-fit: contain;
//   border-radius: 4px;
//   margin: auto;
// }
.box .lazy-image-box {
	width: 60%;
	max-height: 200px;
	object-fit: contain;
	border-radius: 100%;
	margin: auto;
	overflow: hidden;
}

.box p {
	font-size: 16px;
}

.powered {
	margin-top: 10%;
}

.bottom-link {
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-top: 10%;
}

.bottom-link-text {
	text-decoration: underline;
	color: #333;
}
