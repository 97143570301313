body,
html {
	height: 100%;
	margin: 0;
	padding: 0;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
	display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}
html::-webkit-scrollbar {
	display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}
.osahan-menu-fotter .col.bg-white.rounded-circle.mt-n4.px-3.py-2 a {
	width: 68px;
	color: #fff !important;
	height: 68px;
	font-size: 27px !important;
	padding: 16px;
}
.snow-box {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	pointer-events: none;
}

.blank-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.animation-container {
	width: 300px;
	height: 300px;
}

.text {
	font-style: normal;
	font-weight: 600;
	font-size: 23px;
	line-height: 27px;
	text-align: center;
	color: #000000;
}

.button {
	margin-top: 16px;
	padding: 8px 16px;
	font-size: 16px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.matop {
	margin-top: 20%;
}
.rfm-marquee-container {
	overflow: hidden !important;
}
