.modalBody{
   padding: 0;
   position: relative;

}
.image{
    border-radius: 0 0 10px 10px;
}
.content{
    padding: 1rem;
}

.close{
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 10;
    color: rgb(255, 255, 255);
    width: 25px;
    height: 25px;
    background-color:#0000006d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 500;
}

.parentElement{
    position: relative;

.element{
&.button{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
    transition: visibility 0s linear 0.33s, opacity 0.33s linear ,transform  0.33s linear;
    &.show{
        visibility: visible;
        opacity: 1;
        
        transition-delay: 0s;
        margin-bottom: 0;
        transform: translateY(0);

    }
    &.hide{
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
    }
}
}
.changeCartButtonsParent{
    button{
        top: unset !important;
        right: unset !important;
        left: unset !important;
        bottom: unset !important;
    }
}
