.body {
	.cartItems {
		height: calc(100vh - 400px);
		overflow: auto;
		padding: 0 10px;
	}
}
.discount {
	color: #50c878 !important;
	&.active {
		background-color: #50c878 !important;
		color: #fff !important;
		padding: 15px 10px !important;
	}
	font-weight: 500;
	.icon {
		margin-right: 5px;
	}
	.label {
	}
}
.calculatorBox {
	border-radius: 15px 15px 0 0 !important;
}

.beforePrice {
	text-decoration: line-through;
	color: #8c8c8c;
	font-size: 14px;
}

.payment-text {
	display: flex;
	justify-content: center;
	width: 100%;
	font-size: 20px;
	// margin: 10px 0;
	text-align: center;
	padding: 15px;
	color: #fc7b7b;
	color: #dc3545;
	text-shadow: 2px 5px 24px;
}
.onlinePayment {
	// margin-top: 40px;
	padding: 0 0 0;
	justify-content: center;
	gap: 3px;
	flex-wrap: wrap;
	align-items: center;
	> img {
		// width: 20%;
		// max-height: 20px;
		// height: 20px;
		max-width: 32px;
		// width: 20%;
	}
}
.onlinePaymentPadding {
	padding: 8px 10px 10px;
}
.powerd {
	width: 50%;
	margin-bottom: 10px;
}
.onlinePercent {
	right: 0;
	// position: absolute;
	display: flex;
	justify-content: end;

	img {
		width: 35%;
		margin-top: -15%;
		margin-right: -12%;
	}
}
.buttonFree {
	border-width: 1px;
	border-radius: 10px;
	border-style: solid;
	border-color: #fc7b7b;
	box-sizing: border-box;
	background-color: #ffffff;
}
