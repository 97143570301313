.body {
	.cartItems {
		height: calc(100vh - 400px);
		overflow: auto;
		padding: 0 10px;
	}
}
.descriptionContent {
	height: 300px;
	padding: 10px;
	border: 1px solid #eee;
	// background-color: #eeee;
	overflow: auto;
	border-radius: 5px;
}
