.paymentContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;

	background-color: #f5f5f5;
}

.top {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-self: stretch;
	align-items: flex-start;
	// padding: 116px 12px 0px 12px;
	box-sizing: border-box;
	padding-top: 50px;
}

.deliveryText {
	position: relative;
	// margin: 35px 0px 0px 0px;
	font-size: 16px;
	white-space: nowrap;
	box-sizing: border-box;
}

.topBar {
	width: 100%;
	left: 0px;
	top: 0px;
	position: absolute;
	gap: 38%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 29px 33px;
	box-sizing: border-box;
	background-color: rgba(217, 217, 217, 0.2);
}

// .deliveryBar {
//   width: 100%;
//   position: relative;
//   gap: 16px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   box-sizing: border-box;
//   background-color: #e8e8e8;
// }
.deliveryBar {
	width: 100%;
	position: relative;
	gap: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	background-color: #e8e8e8;
	.location-delivery {
		padding: 0 10px;
		display: flex;
		width: calc(100% - 120px);
		overflow: hidden;
	}
}

.hotelName {
	font-size: 18px;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
}

.greenbar {
	width: 120px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 26px 8px 27px 8px;
	box-sizing: border-box;
	background-color: #50c878;
}

.time {
	position: relative;
	color: #f7f7f7;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	margin-bottom: 0;
}

.inputs {
	width: 92.64%;
	position: relative;
	gap: 17.8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	margin: 21px 0px 12px 0px;
	padding: 21.9px 21.9px 21.9px;
	box-sizing: border-box;
	// background-color: #ffffff;
}

.nameInput {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 15.9px 23.9px;
	border-width: 1px;
	border-radius: 10px;
	border-style: solid;
	border-color: #eee;
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
}

.paymentSelect {
	width: 81.47%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	// margin: 0px 0px 16px 37px;
	box-sizing: border-box;
}

.btnPayment {
	// height: 100px;
	// padding: 11px 17px 10px 17px;
	border-width: 1px;
	border-radius: 10px;
	border-style: solid;
	border-color: #fc7b7b;
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// max-width: 33.33%;
	width: 33.33%;
	// max-width: 70px;
	justify-content: space-around;
	line-height: normal;
}

.onlineIcon {
	min-width: 0px;
	min-height: 0px;
	position: relative;

	box-sizing: border-box;
	font-size: 24px;
}

.onlineTitle {
	position: relative;
	margin: 0px 0px 6px 0px;
	text-align: center;
	box-sizing: border-box;
}

.totalContainer {
	width: 100%;
	position: relative;
	gap: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0px 12px 37px;
	padding: 13px 20px;
	border-radius: 10px;
	box-sizing: border-box;
	background-color: #e8e8e8;
	white-space: nowrap !important;
	margin: auto;
}

.totalTitle {
	position: relative;
	font-size: 26px;
	text-align: center;
	box-sizing: border-box;
}

.totalPrice {
	position: relative;
	color: #50c878;
	font-size: 26px;
	font-weight: 600;
	white-space: nowrap !important;
	text-align: center;
	box-sizing: border-box;
}

.completeContainer {
	width: -webkit-fill-available;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 21px 97px 20px 97px;
	// border-radius: 10px;
	box-sizing: border-box;
	background-color: #50c878;
	color: #f5f5f5;
	font-size: 20px;
	white-space: nowrap;
	align-items: center;
	font-weight: 300;
	border: 0;
}
.completeContainerNotValid {
	width: -webkit-fill-available;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 21px 10px;
	box-sizing: border-box;
	background-color: #50c878ac;
	color: #f5f5f5;
	font-size: 15px;
	// white-space: nowrap;
	align-items: center;
	font-weight: 300;
	text-align: center;
}

.backIcon {
	font-size: 14px;
}

.locationIcon {
	font-size: 20px;
}

.paymentOptions {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px; /* Adjust the spacing as desired */
}

.paymentSelects {
	width: fit-content;
	display: flex;
}
.bgSuccess {
	background-color: #50c878 !important;
}

.maxWidth {
	// max-width: 1%;
}

.paymentIcon {
	font-size: 28px;
	color: #fc7b7b;
}

.minWidth {
	min-width: 100px;
}

.successBack {
	border-color: 2px solid#fc7b7b;
}

.euroSign {
	font-size: 28px;
	// font-weight: ;
}

.cashTotal {
	font-size: 20px;
}

.paymentPadding {
	// padding: 0px;
}

.currency {
	width: 33.33%;
	min-width: 90px;
}
.maxWidthCurrency {
	// max-width: fit-content;
}

.money {
	margin: auto;
	padding-top: 20px;
}
.other {
	margin-bottom: 23px;
	text-align: center;
	background-color: #ffffff;
	padding: 12px;
	border-radius: 10px;
	box-shadow: 2px 4px 7px 3px rgba(0, 0, 0, 0.25);
}

.moneyInfo {
	text-align: center;
	font-size: 12px;
	margin: auto;
	color: #727272;
	text-transform: capitalize;
	padding: 0 20px 0 20px;
}
