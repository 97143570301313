#subjectModal {
	position: absolute;
	z-index: 110;
	// min-width: 28rem;
	margin: auto;
	// max-width: 90%;
	// opacity: 0;
	// transition: opacity 500ms ease-out;
	display: flex;
	//   @media (max-width:${devices.tablet}px){
	//     min-width: auto;
	//     width: 90%;
	// }
}

.bgUnset {
	#subjectModal {
		padding: unset;
		// min-width: 35em;
	}
	.subjectModal-box {
		padding: unset;
		flex: 1;
		width: 100%;
		background: unset;
		box-shadow: unset;
	}
	.form-countainer {
		width: unset !important;
	}
	.centerAll {
		display: unset;
	}
}

.fade-bottom {
	transition: all 0.5s;
	&.fadeIn {
		//  top: 10vh;
		transform: translateY(10vh);
	}
	&.fadeOut {
		// top: 100vh;
		transform: translateY(100vh);
	}
}
// #subjectModal.fadeIn {
//   opacity: 1;
//   transition: opacity 500ms ease-out;
// }
// #subjectModal.fadeIn:not(.animation-off) {
//   -webkit-animation: top-to-bottom-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   animation: top-to-bottom-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }
// #subjectModal.fadeOut:not(.animation-off) {
//   -webkit-animation: bottom-to-top-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   animation: bottom-to-top-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }

// #subjectModal.fadeIn.animation-off {
//   -webkit-animation: opacity-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   animation: opacity-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }
// #subjectModal.fadeOut.animation-off {
//   -webkit-animation: opacity-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   animation: opacity-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }

.btn-submited-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: unset !important;
}

.subjectModal-box {
	background: white;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	padding: 0.5em 1.5em;
	margin: auto;
	flex: 1;
	width: 100%;
}
.subjectModal-box > div {
	// margin: 1em 0;
}
.subjectModal-AddInformation {
}
.subjectModal-AddInformation {
	line-height: normal;
}
.subjectModal-AddInformation > label {
	margin: 1em 0 0.5em;
	font-size: 1em;
	font-weight: bold;
	display: block;
	text-align: start;
}
.subjectModal-AddInformation > input {
	width: 100%;
	border: 1px solid;
	border-radius: 10px;
	padding: 5px;
	background-color: #eee;
	text-align: center;
}
.subjectModal-AddInformation > .btn-submited-container {
	margin-top: 20px;
}

.submitedBtn button {
	width: 100px;
	background-color: #208000;
	color: white;
	border-radius: 10px;
	border: 1px solid;
	padding: 8px;
	box-shadow: 1px 1px 5px 0px green;
	text-align: center;
}
.submitedBtn button:hover {
	box-shadow: 1px 1px 9px 0px green;
}
.submitedBtn.closed button {
	background-color: white;
	color: black;
	border: unset;
	box-shadow: 1px 1px 5px 0 #636363;
}
.submitedBtn.closed button:hover {
	box-shadow: 1px 1px 15px 0 #636363;
}
#LoginModal {
	position: fixed;
	z-index: 30;
}

.modal_container {
	display: flex;
	flex-direction: column;
	width: 303px !important;
	//   @include dir-aware(margin, left, -89% !important);
	left: -89% !important;
	z-index: 10 !important;
}

/* #LoginModal.fadeIn {
      -webkit-animation: scale-fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: scale-fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    } */

.modal_container-box {
	position: fixed;
	// // background-color: rgba(0, 0, 0, 0.3);
	// display:flex;
	// justify-content: center;
	// z-index: 1000;
	right: 0;
	top: 0;
	left: 0;
	// bottom: 0;
	// min-height: 100vh;
	z-index: 1300;
	// transition: all 0.s;
	visibility: visible;
	&.hidden {
		// pointer-events: none;
		// user-select: none;
		visibility: hidden;
		// display: none;
	}
}
.modal_container-box .modal_container-box {
	height: unset;
}

/* ------------------------------------------------------------------------- keyframes*/
/* ------------------------------------ */
@-webkit-keyframes scale-fadein {
	0% {
		-webkit-transform: translateY(-150vh);
		transform: translateY(-150vh);
	}
	100% {
		-webkit-transform: translateY(30vh);
		transform: translateY(30vh);
	}
}

@keyframes scale-fadein {
	0% {
		-webkit-transform: translateY(-150vh);
		transform: translateY(-150vh);
	}
	100% {
		-webkit-transform: translateY(20vh);
		transform: translateY(20vh);
	}
}
/* ------------------------------------ */
@-webkit-keyframes scale-fadeout {
	0% {
		-webkit-transform: translateY(20vh);
		transform: translateY(20vh);
	}
	100% {
		-webkit-transform: translateY(-150vh);
		transform: translateY(-150vh);
	}
}

@keyframes scale-fadeout {
	0% {
		-webkit-transform: translateY(20vh);
		transform: translateY(20vh);
	}
	100% {
		-webkit-transform: translateY(-150vh);
		transform: translateY(-150vh);
	}
}
/* ------------------------------------ */

@keyframes bottom-to-top-animation {
	0% {
		opacity: 1;
		display: none;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		display: none;
		-webkit-transform: translateY(-80px);
		transform: translateY(-80px);
	}
}

@-webkit-keyframes bottom-to-top-animation {
	0% {
		opacity: 1;
		display: none;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		display: block;
		transform: translateY(-80px);
	}
}

/* ------------------------------------ */

@keyframes top-to-bottom-animation {
	0% {
		opacity: 0;
		display: none;
		transform: translateY(-80px);
	}
	100% {
		opacity: 1;
		display: block;
		transform: translateY(0);
	}
}

@-webkit-keyframes top-to-bottom-animation {
	0% {
		opacity: 0;
		display: none;
		-webkit-transform: translateY(-80px);
		transform: translateY(-80px);
	}
	100% {
		opacity: 1;
		display: block;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

/* ------------------------------------ */
.Login-box {
	width: 400px;
	padding: 40px;
	background: rgba(0, 0, 0, 0.8);
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	padding: 30px 20px;
	margin: auto;
}

.Login-box > span {
	color: #fff;
	text-align: center;
}

.Description -for-customer.web-e-login {
	font-size: 20px;
}

.RSLEditeMobileNumber.web-e-login {
	font-size: 13px;
}

.mobileAndTokenError.web-e-login {
	font-size: 16px;
	width: 88%;
	text-align: center;
}

@-webkit-keyframes scale-fadeIn {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-fadeIn {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes scale-fadeOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}
@keyframes scale-fadeOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

/* OPACITY ANIMATION */
@-webkit-keyframes opacity-fadeIn {
	0% {
		-webkit-transform: opacity(0.9);
		transform: opacity(0.9);
	}
	100% {
		-webkit-transform: opacity(1);
		transform: opacity(1);
	}
}
@keyframes opacity-fadeIn {
	0% {
		-webkit-transform: opacity(0.9);
		transform: opacity(0.9);
	}
	100% {
		-webkit-transform: opacity(1);
		transform: opacity(1);
	}
}

@-webkit-keyframes opacity-fadeOut {
	0% {
		-webkit-transform: opacity(1);
		transform: opacity(1);
	}
	100% {
		-webkit-transform: opacity(0.9);
		transform: opacity(0.9);
	}
}
@keyframes opacity-fadeOut {
	0% {
		-webkit-transform: opacity(1);
		transform: opacity(1);
	}
	100% {
		-webkit-transform: opacity(0.9);
		transform: opacity(0.9);
	}
}
// .Div_modal_container_box{
//   .hidden

// }

// export default {
//   Div_modal_container_box: styled.div`
//   position: fixed;
//   background-color: rgba(0, 0, 0, 0.3);
//   display:flex;
//   justify-content: center;
//   // align-items: center;
//   z-index: 1000;
//   right: 0;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   height: 100vh;
//   z-index: 1300;
//   // overflow: auto;
//   .modal_container-box {
//     height: unset;
//   }
//   #subjectModal {
//     z-index: 30;
//     min-width: 28em;
//     margin: auto;
//     max-width: 90%;
//   }
//   .bgUnset {
//     #subjectModal {
//       padding: unset;
//       min-width: 35em;
//     }
//     .subjectModal-box {
//       padding: unset;
//       flex: 1;
//       width: 100%;
//     }
//     .form-countainer {
//       width: unset !important;
//     }
//     .centerAll {
//       display: unset;
//     }
//   }
//   #subjectModal.fadeIn:not(.animation-off) {
//     -webkit-animation: scale-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
//       both;
//     animation: scale-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   }
//   #subjectModal.fadeOut:not(.animation-off) {
//     -webkit-animation: scale-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
//       both;
//     animation: scale-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   }

//   #subjectModal.fadeIn.animation-off {
//     -webkit-animation: opacity-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
//       both;
//     animation: opacity-fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   }
//   #subjectModal.fadeOut.animation-off {
//     -webkit-animation: opacity-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
//       both;
//     animation: opacity-fadeOut 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   }

//   .btn-submited-container {
//     display:flex;
//     justify-content: space-evenly;
//     flex-direction: unset !important;
//   }

//   .subjectModal-box {
//     background: white;
//     box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
//     border-radius: 10px;
//     padding: 0.5em 1.5em;
//     margin: auto;
//     flex: 1;
//     width: 100%;
//     // max-height: 90vh;
//     // overflow: auto;
//   }
//   .subjectModal-box > div {
//     margin: 1em 0;
//   }
//   .subjectModal-AddInformation {
//   }
//   .subjectModal-AddInformation {
//     line-height: normal;
//   }
//   .subjectModal-AddInformation > label {
//     margin: 1em 0 0.5empx;
//     font-size: 1em;
//     font-weight: bold;
//     display: block;
//     text-align: start;
//   }
//   .subjectModal-AddInformation > input {
//     width: 100%;
//     border: 1px solid #ccc;
//     border-radius: 10px;
//     padding: 5px;
//     background-color: #eee;
//     text-align: center;
//   }
//   .subjectModal-AddInformation > .btn-submited-container {
//     margin-top: 20px;
//   }
// `

// }

// .modalBox{
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1055;
//     display: none;
//     width: 100%;
//     height: 100%;
//     overflow-x: hidden;
//     overflow-y: auto;
//     outline: 0;
//     display: block;

//     .modal-dialog{
//         position: relative;
//         width: auto;
//         margin: 0.5rem;
//         pointer-events: none;

//         .modal-content {
//             position: relative;
//             display: flex;
//             flex-direction: column;
//             width: 100%;
//             pointer-events: auto;
//             background-color: #fff;
//             background-clip: padding-box;
//             border: 1px solid rgba(0, 0, 0, .2);
//             border-radius: 0.3rem;
//             outline: 0;
//         }

//     }
//     &.fade {
//         transition: opacity .15s linear;
//         .modal-dialog {
//             transition: transform .3s ease-out;
//             transform: translate(0, -50px);
//         }
//     }
//     &.show {
//         .modal-dialog {
//             transform: none;
//         }
//     }

// }
