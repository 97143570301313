.navbar {
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-bottom: 0rem !important;
	// background-color: #efefef;
	// border-bottom: 2px solid #BEBEBE;
	width: 100%;
	z-index: 150;
}

.navBarBrand {
	font-size: 16px;
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.searchInput {
	width: 100%;
	height: 40px;
	border: none;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 10px;
	margin-top: -60%;
	position: sticky;

	&::placeholder {
		text-align: center;
	}
}

.inputIcon {
	height: 1.5rem;
	width: 1.5rem;
	color: red;
	padding: 4px;
	position: absolute;
	box-sizing: border-box;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
}

.inputWrapper {
	position: relative;
}

.sliderImage {
	width: 100%;
	height: 50%;
	border-radius: 11px;
}

.bannerSlider {
	width: 100%;
	height: 50%;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.catCard {
	border-radius: 10px;
	text-align: center;
	// width: 80px;
	// height: 80px;
	// left: 24px;
	margin-top: 5px;
	// margin-top: 11px;
	img {
		box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25));
	}
}

.catName {
	margin-top: 2px;
	// margin-top: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #000000;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.catImage {
	border-radius: 10px;
	width: -webkit-fill-available;
}
.cardContainer {
	// display: flex;
	// align-items: center;
	> a {
		padding: 0 5px;
	}
}

.languageSelect {
	// background-color: #FC7B7B;
	color: #020202;
}
.flagSelect {
	width: 25px;
}
.empty-space-cat {
	height: 160px;
}

.swiper-pagination-bullet {
	background-color: white;
	opacity: 100% !important;
}
.swiper-pagination-bullet-active {
	background-color: rgb(0, 89, 255) !important;
}
