.overlay {
	// border-radius: 34px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 5px;
	color: #fff;
	.content {
		border-radius: 15px;
		overflow: hidden;
	}
}
.modal_dialog {
	max-width: 384px;
	width: 100%;
	// padding-bottom: calc(748 / 594 * 100%);

	// height: 449.34px;
	// width: 95%;
	// width: 344.75px;
	// height: 449.34px;
}
.fullscreen-popup {
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// // transform: translate(-50%, -50%);
	// background-color: #ffffffb8;
	// padding: 20px;
	// width: 100%;
	// overflow-y: auto;
	// border-radius: 4px;
	// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	// height: 100%;
}

.popup-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background-image: url("../../assets/baklava/bg2.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	// max-width: 344.75px;
	width: 100%;

	// // height: 449.34px;
	padding-bottom: calc(748 / 580 * 100%);
	position: relative;
}

.popup-title {
	display: flex;
	text-align: center;
	justify-content: center;
	margin-top: 40px;
	letter-spacing: 3px;

	// margin: 60px 0 20px 0;
	// position: absolute;
	// top: 15%;
	text-align: center;
	width: 100%;
	h1 {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 0;
	}
}

.language-title {
	font-size: 16px;
	// margin-top: 40px;
	// margin-top: 20%;
}

.box-container {
	overflow: hidden;

	img {
		width: 100%;
		margin: auto;
	}
}

.box {
	min-width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: solid 1px #ff5252;
	padding: 5px;
	border-radius: 10px;
}

.button {
	background-color: #fff;
	border-color: #fff;
	width: 80%;
	border-radius: 25px;
	color: #bd000d;
	font-size: 18px;
	font-weight: 500;

	&:hover {
		background-color: #fff;
		border-color: #fff;
	}
}

.box img {
	width: 70%;
	max-height: 200px;
	object-fit: cover;
	border-radius: 4px;
	margin-bottom: 10px;
}

.box p {
	font-size: 16px;
}

.powered {
	margin-top: 10%;
}

.bottom-link {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10%;
}

.bottom-link-text {
	text-decoration: underline;
	color: #333;
}
.popup-title-2 {
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	margin: 10px 15px;
}
.text-box {
	p {
		margin-bottom: 0;
		letter-spacing: 1px;
	}
	.small {
		font-size: 13px;
		font-weight: 400;
	}
	.large {
		font-size: 16px;
		font-weight: 900;
	}
}

// .baklava {
// 	padding-bottom: 50%;
// }

.popup-box {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.popup-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px 0;
	align-items: center;
}
.close-icon {
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 35px;
	z-index: 100;
}
