.wrap {
	display: flex;
	justify-content: center;
	width: 100%;
}
.container {
	background-color: #cfdeef;
	border: 1px solid #cccc;
	// padding: 5px;
	border-radius: 8px;
	max-width: 400px;
	margin: auto;
	// margin: 0 2px;
	width: 100%;
	.box {
		position: relative;
		margin: 5px 10px;
	}
	.head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		font-weight: 500;
		position: relative;
		.amount {
			font-size: 13px;
			// width: 27px;
		}
		.text {
			position: absolute;
			font-size: 12px;
			transform: scale(0.8);
			left: 0;
			right: 0;
			text-align: center;
			letter-spacing: -0.2px;
			// max-width: 235px;
			margin: auto;
			white-space: pre-wrap;
			width: calc(100% - 40px);
			// overflow: hidden;
		}
	}
	.body {
		margin-top: 10px;
		display: flex;
		align-items: center;
		.radialBox {
			position: relative;
			height: 7px;
			background-color: white;
			border-radius: 5px;
			// overflow: hidden;
			display: flex;
			align-items: center;
			width: 100%;
			z-index: 100;

			.radialBoxActive {
				position: absolute;
				left: 0;
				height: 100%;
				background-color: #013366;
				border-radius: 5px;
				transition: all 0.3s;
				&.isComplete {
					background-color: #50c879;
				}
			}
			.boatIcon {
				position: absolute;
				width: 40px;
				z-index: 100;
				bottom: 0;
				transition: all 0.3s;

				// left: 0px;
			}
		}
		.giftBox {
			// position: absolute;
			// right: 0;
			// z-index: 100;
			margin-bottom: 5px;
			.giftIcon {
				width: 20px;
			}
		}
	}
}
